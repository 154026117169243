import { gql } from '@apollo/client';
import { SALES_ORDER_FRAGMENT } from './SALES_ORDER_FRAGMENT';

export const CANCEL_SALES_ORDER_MUTATION = gql`
  mutation CancelSalesOrder($id: Int!) {
    o {
      cancelSalesOrder(id: $id) {
        success
        message {
          code
          message
        }
        content {
          ...SalesOrderFragment
        }
      }
    }
  }
  ${SALES_ORDER_FRAGMENT}
`;
