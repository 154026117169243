import {
  CButton,
  CCol,
  CForm,
  CFormControl,
  CInputGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import { FormEventHandler, useEffect, useState } from 'react';
import { RequireLabel } from '../../components/RequireLabel';
import { DEFAULT_API_URL } from '../../graphql-client';
import { theme } from '../../types/theme';
import { store } from '../../utils/store';

export const ChangeServerModal = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}) => {
  const [validated, setValidated] = useState(false);

  const [serverUrl, setServerUrl] = useState('');

  const loadBaseUrl = async () => {
    const baseUrl = await store.getBaseUrl();
    setServerUrl(baseUrl || DEFAULT_API_URL);
  };

  useEffect(() => {
    loadBaseUrl();
  }, [visible]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }
    setValidated(true);
    await store.setBaseUrl(serverUrl);
    window.location.reload();
  };

  return (
    <CModal
      size="lg"
      className="show"
      visible={visible}
      onDismiss={() => {
        setVisible(false);
        setServerUrl('');
        setValidated(false);
      }}
    >
      <CModalHeader onDismiss={() => setVisible(false)}>
        <CModalTitle>Thay đổi server</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm noValidate={true} validated={validated} onSubmit={handleSubmit} className="mb-3">
          <CRow>
            <CCol>
              <RequireLabel text="Server Url" htmlFor="serverUrl" required={true} />
              <CInputGroup>
                <CFormControl
                  id="serverUrl"
                  required={true}
                  value={serverUrl}
                  onChange={(e) => setServerUrl(e.target.value)}
                />
              </CInputGroup>
            </CCol>
          </CRow>

          <CButton
            style={{ backgroundColor: theme.colors.primary, borderColor: theme.colors.primary }}
            type="submit"
            className="mt-3"
          >
            Xác nhận
          </CButton>
        </CForm>
      </CModalBody>
    </CModal>
  );
};
