import { gql } from '@apollo/client';
import { ATTR_FRAGMENT } from './ATTR_FRAGMENT';

export const ATTRS_QUERY = gql`
  query Attrs($type: EntityType!) {
    md {
      attrs(type: $type) {
        ...AttrFragment
      }
    }
  }
  ${ATTR_FRAGMENT}
`;
