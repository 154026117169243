import { gql } from '@apollo/client';
import { MCP_FRAGMENT } from './MCP_FRAGMENT';
export const MCP_LIST_QUERY = gql`
  query McpList(
    $page: Int!
    $take: Int!
    $status: ProgramStatus
    $salesmanCd: String
    $mcpCd: String
    $startDate: Date
    $endDate: Date
    $salesRegionId: Int
  ) {
    mcp {
      mcpList(
        page: $page
        take: $take
        status: $status
        startDate: $startDate
        endDate: $endDate
        salesmanCd: $salesmanCd
        mcpCd: $mcpCd

        salesRegionId: $salesRegionId
      ) {
        total
        data {
          ...McpFragment
        }
      }
    }
  }
  ${MCP_FRAGMENT}
`;
