import { CFormFeedback } from '@coreui/react';
import { useCallback, useRef } from 'react';
import { RefCallBack } from 'react-hook-form';
import Select from 'react-select';

export type DataSelect<T> = {
  value: T;
  label: string;
};

export const CustomSelect = <T extends any>({
  id,
  values,
  inputValue,
  isResetInput = true,
  list,
  required,
  requiredText,
  disabled,
  closeMenuOnSelect,
  invalid,
  ref,
  placeHolder,
  loading,
  onMenuScrollToBottom,
  onInputChange,
  onValuesChange,
  onValueChange,
}: {
  id?: string;
  isResetInput?: boolean;
  inputValue?: string;
  values?: DataSelect<any>[];
  list: DataSelect<T>[];
  required?: boolean;
  requiredText?: string;
  disabled?: boolean;
  invalid?: boolean;
  ref?: RefCallBack;
  loading?: boolean;
  closeMenuOnSelect?: boolean;
  onMenuScrollToBottom?: () => void;
  onInputChange?: (_: string) => void;
  placeHolder?: string;
  onValuesChange?: (_: T[]) => void;
  onValueChange?: (_: T) => void;
}) => {
  const selectRef = useRef<any>(null);
  const noop = useCallback(() => {
    // nop
  }, []);

  return (
    <div className="position-relative">
      <Select
        inputId={id}
        inputValue={inputValue}
        placeholder={placeHolder || 'Lựa chọn'}
        onInputChange={(val, { action }) => {
          if (!onInputChange) {
            return;
          }
          if (isResetInput) {
            clearTimeout(selectRef.current);
            selectRef.current = setTimeout(() => {
              onInputChange(val);
            }, 500);
            return;
          }
          if (action === 'input-change') {
            onInputChange(val);
          }
        }}
        value={values}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={!!onValuesChange}
        isLoading={loading}
        isDisabled={disabled}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isClearable={!required}
        options={list.map((i) => ({ value: i.value, label: i.label }))}
        menuPosition={'fixed'}
        ref={ref}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (base) =>
            invalid
              ? {
                  ...base,
                  borderColor: '#DC143C',
                }
              : {
                  ...base,
                },
        }}
        onChange={(val: any) => {
          if (onValuesChange) {
            onValuesChange(val.map((i: any) => i.value));
          } else {
            onValueChange?.(val ? val.value : null);
          }
        }}
      />
      {required && (
        <>
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: '100%',
              height: 0,
              position: 'absolute',
            }}
            value={(values || []).join()}
            onChange={noop}
            onFocus={() => selectRef.current?.focus()}
            required={required}
          />
          <CFormFeedback invalid={true}>
            {requiredText || 'Please provide at least one value.'}
          </CFormFeedback>
        </>
      )}
    </div>
  );
};
