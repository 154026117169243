import { gql } from '@apollo/client';

export const PENDING_WAREHOUSE_PRODUCTS_QUERY = gql`
  query PendingWarehouseProducts(
    $source: TransactionSource
    $distributorId: Int
    $warehouseId: Int
    $productId: Int
  ) {
    rp {
      pendingWarehouseProducts(
        source: $source
        distributorId: $distributorId
        warehouseId: $warehouseId
        productId: $productId
      ) {
        id
        type
        qty
        unitId
        unitPrice
        baseQty
        baseUnitId
        baseUnitPrice
        lotNbr
        startDate
        endDate
        product {
          id
          cd
          name
        }
        warehouse {
          id
          name
          distributor {
            id
            name
          }
        }
        transaction {
          id
          status
          source
          salesOrderId
          purchaseReceiptId
        }
      }
    }
  }
`;
