import { Portal } from '@material-ui/core';
import RNDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarContainer = ({ children }: { children: any }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

export const DatePicker = ({
  id,
  required,
  value,
  minValue,
  maxValue,
  disabled,
  isClearable,
  placeHolder,
  onChange,
  onSelect,
}: {
  id?: string;
  required?: boolean;
  value?: Date;
  minValue?: Date;
  maxValue?: Date;
  isClearable?: boolean;
  disabled?: boolean;
  placeHolder?: string;
  onChange?: (val: Date) => void;
  onSelect?: (val: Date) => void;
}) => {
  return (
    <RNDatePicker
      id={id}
      required={required}
      selected={value}
      minDate={minValue}
      maxDate={maxValue}
      isClearable={isClearable}
      disabled={disabled}
      placeholderText={placeHolder}
      onChange={(date: Date) => onChange && onChange(date)}
      onSelect={(date: Date) => onSelect && onSelect(date)}
      className="form-control d-block"
      wrapperClassName="d-block"
      autoComplete="off"
      dateFormat="dd/MM/yyyy"
      popperContainer={CalendarContainer}
    />
  );
};
