import { gql } from '@apollo/client';

export const WORKING_SALESMANS_QUERY = gql`
  query WorkingSalesmans($distributorId: Int!) {
    workingSalesmans(distributorId: $distributorId) {
      id
      cd
      name
    }
  }
`;
