import { gql } from '@apollo/client';
import { PRODUCT_GROUP_FRAGMENT } from './PRODUCT_GROUP_FRAGMENT';

export const PRODUCT_GROUP_QUERY = gql`
  query ProductGroup($id: Int!) {
    md {
      productGroup(id: $id) {
        ...ProductGroupFragment
      }
    }
  }
  ${PRODUCT_GROUP_FRAGMENT}
`;
