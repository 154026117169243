import { gql } from '@apollo/client';

export const DELETE_MCP_LINE_MUTATION = gql`
  mutation DeleteMcpLine($id: Int!) {
    mcp {
      deleteMcpLine(id: $id) {
        success
        message {
          code
          message
        }
        content
      }
    }
  }
`;
