import { gql } from '@apollo/client';
import { KPI_CONFIG_FRAGMENT } from './KPI_CONFIG_FRAGMENT';

export const KPI_CONFIG_QUERY = gql`
  query KpiConfig($id: Int!) {
    p {
      kpiConfig(id: $id) {
        ...KpiConfigFragment
      }
    }
  }
  ${KPI_CONFIG_FRAGMENT}
`;
