import gql from 'graphql-tag';

export const SURVEY_FRAGMENT = gql`
  fragment SurveyFragment on Survey {
    __typename
    id
    cd
    status
    title
    description
    startDate
    endDate
    multiple

    surveyQuestions {
      id
      title
      isFreeText
      surveyAnswers {
        id
        title
        requiredOtherTextAnswer
      }
    }

    surveyApplicableCustomerAttrs {
      id
      attrId
      attrDetailId
    }

    surveyApplicableSalesRegions {
      id
      salesRegion {
        id
        name
      }
    }
  }
`;
