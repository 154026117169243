import { gql } from '@apollo/client';

export const KPI_FORMULAS_QUERY = gql`
  query KpiFormulas(
    $name: String
    $status: ProgramStatus
    $formulas: KpiFormulaType
    $kpiFormulaCd: String
  ) {
    p {
      kpiFormulas(name: $name, status: $status, formulas: $formulas, kpiFormulaCd: $kpiFormulaCd) {
        id
        cd
        status
        name
        formula
        description
        creator {
          cd
          name
        }
        createdAt
      }
    }
  }
`;
