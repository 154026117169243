import { gql } from '@apollo/client';
import { PRODUCT_GROUP_FRAGMENT } from './PRODUCT_GROUP_FRAGMENT';

export const CREATE_OR_UPDATE_PRODUCT_GROUP_MUTATION = gql`
  mutation CreateOrUpdateProductGroup($data: ProductGroupInput!) {
    md {
      createOrUpdateProductGroup(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...ProductGroupFragment
        }
      }
    }
  }
  ${PRODUCT_GROUP_FRAGMENT}
`;
