import gql from 'graphql-tag';

export const PRODUCT_GROUP_FRAGMENT = gql`
  fragment ProductGroupFragment on ProductGroup {
    __typename
    id
    cd
    name
    description
    isBundle
    productGroupItems {
      id
      productId
      rate
    }
  }
`;
