import { gql } from '@apollo/client';

export const WORKING_DISTRIBUTORS_QUERY = gql`
  query WorkingDistributors {
    workingDistributors {
      id
      cd
      name
      customer {
        id
        name
        customerPriceClassId
      }

      warehouses {
        id
        name
        pickOrder
        allowPurchase
        allowSales
        allowReceipt
        allowIssue
        allowAdjust
        allowTransfer
      }
    }
  }
`;
