import { gql } from '@apollo/client';

export const DELETE_DISCOUNT_MUTATION = gql`
  mutation DeleteDiscount($id: Int!) {
    p {
      deleteDiscount(id: $id) {
        success
        message {
          code
          message
        }
        content
      }
    }
  }
`;
