import { gql } from '@apollo/client';

export const MCP_LINE_LIST_QUERY = gql`
  query McpLineList($mcpId: Int!, $page: Int!, $take: Int!) {
    mcp {
      mcpLineList(mcpId: $mcpId, page: $page, take: $take) {
        total
        data {
          id
          weekdays
          weeksOfMonth
          distributors {
            id
            cd
            name
          }
          mcpDistributors {
            id
            distributor {
              id
              cd
              name
            }
          }
          customer {
            id
            cd
            name
            phone11
          }
        }
      }
    }
  }
`;
