import { gql } from '@apollo/client';

export const STATUS_MUTATION = gql`
mutation StatusShipper($id:Int!){
    md{
      statusShipper(id: $id){
        success
        message{
          code
          message
        }
        content
      }
    }
  }
`;
