import { gql } from '@apollo/client';

export const VISUAL_LIST_QUERY = gql`
  query VisualList(
    $page: Int!
    $take: Int!
    $filter: String
    $status: ProgramStatus
    $visualCd: String
  ) {
    p {
      visualList(page: $page, take: $take, filter: $filter, status: $status, visualCd: $visualCd) {
        total
        data {
          id
          cd
          status
          name
          description
          startDate
          endDate
        }
      }
    }
  }
`;
