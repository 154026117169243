import { gql } from '@apollo/client';
import { SALES_REGION_FRAGMENT } from './SALES_REGION_FRAGMENT';

export const SALES_REGION_QUERY = gql`
  query SalesRegion($id: Int!) {
    mcp {
      salesRegion(id: $id) {
        ...SalesRegionFragment
      }
    }
  }
  ${SALES_REGION_FRAGMENT}
`;
