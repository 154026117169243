import { gql } from '@apollo/client';

export const COMMANDO_QUERY = gql`
  query Commando($id: Int!) {
    mcp {
      commando(id: $id) {
        id
        cd
        status
        description
        sellTypes
        inRangeMeter
        hasOrderRequiredTasks
        allowOutPlanVisit
        allowNewCustomerVisit
        startDate
        endDate
        lastGeneratedDate
        salesRegion {
          id
          cd
          name
          description
        }
        salesman {
          id
          name
          cd
        }
        provinceSalesRegion {
          id
          name
          districts {
            id
            name
            wards {
              id
              name
            }
          }
        }
        commandoDistributors {
          id
          distributor {
            id
            cd
            name
          }
        }
      }
    }
  }
`;
