import { gql } from '@apollo/client';

export const PURCHASE_RECEIPT_LIST_QUERY = gql`
  query PurchaseReceiptList(
    $page: Int!
    $take: Int!
    $startDate: Date
    $endDate: Date
    $status: OrderStatus
    $distributorCd: String
    $vendorCd: String
    $employeeId: Int
    $purchaseReceiptCd: String
  ) {
    o {
      purchaseReceiptList(
        page: $page
        take: $take
        startDate: $startDate
        endDate: $endDate
        status: $status
        vendorCd: $vendorCd
        distributorCd: $distributorCd
        employeeId: $employeeId
        purchaseReceiptCd: $purchaseReceiptCd
      ) {
        total
        data {
          id
          cd
          orderDate
          orderType
          orderStatus
          orderTotal
          description
          externalReference
          vendor {
            id
            cd
            name
          }
          creator {
            id
            cd
            name
          }
          distributor {
            id
            cd
            name
          }
        }
      }
    }
  }
`;
