import { gql } from '@apollo/client';

export const DELETE_KPI_FORMULA_MUTATION = gql`
  mutation DeleteKpiFormula($id: Int!) {
    p {
      deleteKpiFormula(id: $id) {
        success
        message {
          code
          message
        }
        content
      }
    }
  }
`;
