import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const LoadingCard = () => (
  <CCard>
    <CCardHeader>
      <div className="d-flex justify-content-between align-items-center">
        <Skeleton className="w-50" />
      </div>
    </CCardHeader>

    <CCardBody>
      <Skeleton className="w-100" />
      <div className="mt-3"></div>
      <Skeleton className="w-100 " count={5} />
    </CCardBody>
  </CCard>
);
