import { gql } from '@apollo/client';

export const KPI_CONFIG_LIST_QUERY = gql`
  query KpiConfigList(
    $page: Int!
    $take: Int!
    $name: String
    $status: ProgramStatus
    $description: String
    $kpiConfigCd: String
    $startDate: Date
    $endDate: Date
  ) {
    p {
      kpiConfigList(
        page: $page
        take: $take
        name: $name
        status: $status
        description: $description
        kpiConfigCd: $kpiConfigCd
        startDate: $startDate
        endDate: $endDate
      ) {
        total
        data {
          id
          cd
          status
          target
          name
          description
          startDate
          endDate
        }
      }
    }
  }
`;
