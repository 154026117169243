import { gql } from '@apollo/client';

export const WARDS_QUERY = gql`
  query Wards($districtId: Int!) {
    md {
      wards(districtId: $districtId) {
        id
        name
      }
    }
  }
`;
