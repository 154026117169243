export const logoMin = [
  '160 160',
  `
  <title>coreui logo</title>
  <g>
    <g style="fill:#fff;">
      <path class="prefix__cls-1"
            d="M125 47.091L86 24.574a12 12 0 00-12 0L35 47.091a12.034 12.034 0 00-6 10.392v45.034a12.034 12.034 0 006 10.392l39 22.517a12 12 0 0012 0l39-22.517a12.034 12.034 0 006-10.392V57.483a12.034 12.034 0 00-6-10.392zm-2 55.426a4 4 0 01-2 3.464l-39 22.516a4 4 0 01-4 0l-39-22.516a4 4 0 01-2-3.464V57.483a4 4 0 012-3.464l39-22.516a4 4 0 014 0l39 22.516a4 4 0 012 3.464z" />
        <path d="M69.396 55.978l14.94.609L96.82 68.976l3.48 17.06-11.462 16.451-19.647 2.64z" fill="none"
            stroke="currentColor" stroke-width="8.899" />
    </g>
  </g>
`,
];
