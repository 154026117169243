import { createContext } from 'react';

export enum DataConfigLocation {
  Name,
  ProcedureName,
  IsWeb,
  IsMobile,
  IsActive
}

export const EnumDefined = createContext<{
  }>({
    state: undefined,
    dispatch: () => null,
  });