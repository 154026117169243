import { gql } from '@apollo/client';
import { TRADE_FRAGMENT } from './TRADE_FRAGMENT';

export const CREATE_OR_UPDATE_TRADE_MUTATION = gql`
  mutation CreateOrUpdateTrade($data: TradeInput!) {
    p {
      createOrUpdateTrade(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...TradeFragment
        }
      }
    }
  }
  ${TRADE_FRAGMENT}
`;
