import { gql } from '@apollo/client';

export const DISCOUNT_LIST_QUERY = gql`
  query DiscountList(
    $page: Int!
    $take: Int!
    $filter: String
    $status: ProgramStatus
    $discountCd: String
    $startDate: Date
    $endDate: Date
    $creatorCd: String
    $isBundle: Int
    $isFirstOrder: Int
    $externalReference: String
    $salesRegionId: Int
  ) {
    p {
      discountList(
        page: $page
        take: $take
        filter: $filter
        status: $status
        discountCd: $discountCd
        startDate: $startDate
        endDate: $endDate
        creatorCd: $creatorCd
        isBundle: $isBundle
        isFirstOrder: $isFirstOrder
        externalReference: $externalReference
        salesRegionId: $salesRegionId
      ) {
        total
        data {
          id
          cd
          description
          status
          startDate
          endDate
          isBundle
          breakBy
          multiple
          highestLevel
          excluded
          isManual
          firstOrder
          allowChangeLevel
          internalRef
          externalRef
          sortOrder
        }
      }
    }
  }
`;
