import gql from 'graphql-tag';

export const PURCHASE_RECEIPT_FRAGMENT = gql`
  fragment PurchaseReceiptFragment on PurchaseReceipt {
    __typename
    id
    cd
    orderDate
    orderStatus
    orderTotal
    orderType
    description
    externalReference
    salesOrderId
    deliveryNote {
      id
    }
    vendor {
      id
      name
      phone11
      fullAddress
    }
    distributor {
      id
      cd
      name
      phone11
    }
    employee {
      id
      name
    }
    creator {
      id
      cd
      name
    }
    orderItems {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      product {
        cd
        id
        name
        tax
      }
      warehouse {
        id
        name
      }
    }
    purchaseReceiptTrackLots {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      lotNbr
      startDate
      endDate
      warehouseId
      productId
      product {
        cd
        id
        name
        tax
      }
      warehouse {
        id
        name
      }
    }
  }
`;
