import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_COMMANDO_MUTATION = gql`
  mutation CreateOrUpdateCommando($data: CommandoInput!) {
    mcp {
      createOrUpdateCommando(data: $data) {
        success
        message {
          code
          message
        }
        content {
          id
          cd
          status
          sellTypes
          description
          inRangeMeter
          hasOrderRequiredTasks
          allowOutPlanVisit
          allowNewCustomerVisit
          startDate
          endDate
          lastGeneratedDate
          salesman {
            id
            cd
            name
          }
          commandoDistributors {
            id
            distributor {
              id
              cd
              name
            }
          }
        }
      }
    }
  }
`;
