import { gql } from '@apollo/client';
import { CHECKOUT_REASON_FRAGMENT } from './CHECKOUT_REASON_FRAGMENT';

export const CREATE_OR_UPDATE_CHECKOUT_REASON_MUTATION = gql`
  mutation CreateOrUpdateCheckOutReason($data: CheckOutReasonInput!) {
    mcp {
      createOrUpdateCheckOutReason(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...CheckOutReasonFragment
        }
      }
    }
  }
  ${CHECKOUT_REASON_FRAGMENT}
`;
