import { gql } from '@apollo/client';
import { ATTR_FRAGMENT } from './ATTR_FRAGMENT';

export const ATTR_QUERY = gql`
  query Attr($id: Int!, $type: EntityType!) {
    md {
      attr(id: $id, type: $type) {
        ...AttrFragment
      }
    }
  }
  ${ATTR_FRAGMENT}
`;
