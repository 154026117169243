import { gql } from '@apollo/client';
import { SALES_ORDER_FRAGMENT } from './SALES_ORDER_FRAGMENT';

export const PROCESS_SALES_ORDER_MUTATION = gql`
  mutation ProcessSalesOrder($id: Int!) {
    o {
      processSalesOrder(id: $id) {
        success
        message {
          code
          message
        }
        content {
          ...SalesOrderFragment
        }
      }
    }
  }
  ${SALES_ORDER_FRAGMENT}
`;
