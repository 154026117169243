import { gql } from '@apollo/client';

export const CLOSE_SURVEY_MUTATION = gql`
  mutation CloseSurvey($id: Int!) {
    p {
      closeSurvey(id: $id) {
        success
        message {
          code
          message
        }
        content {
          id
          status
        }
      }
    }
  }
`;
