import gql from 'graphql-tag';

export const DISTRIBUTOR_FRAGMENT = gql`
  fragment DistributorFragment on Distributor {
    __typename
    id
    cd
    name
    contactName1
    phone11
    address1
    isActive
    email
    startDate
    endDate
    distributorType {
      id
      cd
      name
    }
    customer {
      id
      name
    }

    ward {
      id
      name
      district {
        id
        name
        province {
          id
          name
        }
      }
    }

    warehouses {
      id
      name
      description
      pickOrder
      allowPurchase
      allowSales
      allowReceipt
      allowIssue
      allowAdjust
      allowTransfer

      address1

      salesman {
        id
        cd
        name
      }

      ward {
        id
        name
        district {
          id
          name
          province {
            id
            name
          }
        }
      }
    }

    buyers {
      id
      buyer {
        id
        name
      }
    }

    sellers {
      id
      seller {
        id
        name
      }
    }
  }
`;
