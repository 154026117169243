import { gql } from '@apollo/client';

export const TRADE_LIST_QUERY = gql`
  query TradeList(
    $page: Int!
    $take: Int!
    $filter: String
    $status: ProgramStatus
    $tradeCd: String
    $salesRegionId: Int
  ) {
    p {
      tradeList(
        page: $page
        take: $take
        filter: $filter
        status: $status
        tradeCd: $tradeCd
        salesRegionId: $salesRegionId
      ) {
        total
        data {
          id
          cd
          description
          status
          breakBy
          multiple
          autoLevel
          registerStartDate
          registerEndDate
          startDate
          endDate
        }
      }
    }
  }
`;
