import { gql } from '@apollo/client';
import { KPI_FORMULA_FRAGMENT } from './KPI_FORMULA_FRAGMENT';

export const KPI_FORMULA_QUERY = gql`
  query KpiFormula($id: Int!) {
    p {
      kpiFormula(id: $id) {
        ...KpiFormulaFragment
      }
    }
  }
  ${KPI_FORMULA_FRAGMENT}
`;
