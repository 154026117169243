import { createContext, Dispatch, useReducer } from 'react';

type UiData = {
  sidebarShow?: boolean;
};

type Action = { type: 'SET'; sidebarShow?: boolean };

export const UiContext = createContext<{ state: UiData; dispatch: Dispatch<Action> }>({
  state: {},
  dispatch: () => null,
});

export const uiReducer = (_state: UiData, action: Action): UiData => {
  return {
    sidebarShow: action.sidebarShow,
  };
};

export const UiProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(uiReducer, {});
  return <UiContext.Provider value={{ state, dispatch }}>{children}</UiContext.Provider>;
};
