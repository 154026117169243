import gql from 'graphql-tag';

export const SALES_ORDER_FRAGMENT = gql`
  fragment SalesOrderFragment on SalesOrder {
    __typename
    id
    cd
    orderDate
    orderStatus
    orderType
    sellType
    total
    orderSource
    orderTotal
    description
    deliveryAddress
    receiver
    salesOrderBeforeReturnId
    discountMoney
    percentTotal
    vat
    deliveryNote {
      id
      status
      createdAt
      note
      shipper {
        id
        cd
        name
        phone11
        email
        citizenIdentityCard
        photoId
        address1
        distributorId
      }
    }
    salesOrderBeforeReturn {
      id
      cd
      orderDate
      orderStatus
      orderType
      sellType
      orderSource
      orderTotal
      orderTotalAfterTax
      discountMoney
      description
      salesOrderBeforeReturnId
      deliveryNote {
        id
      }
      salesOrderBeforeReturn {
        id
        cd
        salesOrderTrackLots {
          id
          qty
          unitId
          unitPrice
          unitPriceAfterTax
          baseQty
          baseUnitId
          baseUnitPrice
          lotNbr
          startDate
          endDate
          product {
            cd
            id
            name
            tax
          }
          warehouse {
            id
            name
          }
        }
      }
      reasonEditOrder {
        id
        name
        description
      }
      customer {
        id
        cd
        name
        customerPriceClassId
        address1
        phone11
        fullAddress
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
      }
      distributor {
        id
        cd
        name
        phone11
        fullAddress
      }
      salesman {
        id
        cd
        name
      }
      enjoyDiscounts {
        id
        isManual
        discount {
          description
        }
        discountId
        discountLevels {
          id
          rewardId
          turnCount
          percentage
          amount
          discountLevelId
          productSelected {
            id
            qty
            productId
          }
        }
      }
      orderItems {
        id
        qty
        unitId
        unitPrice
        unitPriceAfterTax
        baseQty
        baseUnitId
        baseUnitPrice
        product {
          cd
          id
          name
          tax
        }
        warehouse {
          id
          name
        }
        totalPrice
        totalPriceAfterTax
        isFreeItem
      }
      salesOrderTrackLots {
        id
        qty
        unitId
        unitPrice
        unitPriceAfterTax
        baseQty
        baseUnitId
        baseUnitPrice
        lotNbr
        startDate
        endDate
        product {
          cd
          id
          name
          tax
        }
        warehouse {
          id
          name
        }
      }
    }
    reasonEditOrder {
      id
      name
      description
    }
    customer {
      id
      cd
      name
      customerPriceClassId
      address1
      phone11
      fullAddress
      ward {
        id
        name
        district {
          id
          name
          province {
            id
            name
          }
        }
      }
    }
    creator {
      id
      cd
      name
    }
    distributor {
      id
      cd
      name
      phone11
      fullAddress
    }
    salesman {
      id
      cd
      name
    }
    enjoyDiscounts {
      id
      isManual
      discount {
        description
      }
      discountId
      discountLevels {
        id
        rewardId
        turnCount
        percentage
        amount
        discountLevelId
        productSelected {
          id
          qty
          productId
        }
      }
    }
    salesOrderDiscounts {
      id
      isManual
      salesOrderId
      discountId
      discount {
        id
        cd
        description
        status
        startDate
        endDate
        isBundle
        breakBy
        multiple
        sortOrder
        highestLevel
        buyGive
        excluded
        allowChangeLevel
        isManual
        firstOrder
        internalRef
        externalRef
        sortOrder
        buyGive
        discountLevels {
          id
          level
          description
          breakValue
          discountLevelRewards {
            id
            description
            isDefault
            isBundle
            amount
            percentage
            percentageMoney
            qty
            discountLevelRewardProducts {
              id
              productId
              rate
            }
          }
          discountLevelConditions {
            id
            productId
            minQty
          }
        }
      }
      salesOrderDiscountLevels {
        id
        totalAmount
        percentage
        percentageMoney
        totalTurn
        discountLevelId
        isSelected
        salesOrderFreeItems {
          id
          qty
          productId
          product {
            id
            cd
            name
          }
        }
      }
    }
    orderItems {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      product {
        cd
        id
        name
        tax
      }
      warehouse {
        id
        name
      }
      totalPrice
      totalPriceAfterTax
      isFreeItem
      discount {
        id
        cd
      }
    }
    salesOrderTrackLots {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      lotNbr
      startDate
      endDate
      warehouseId
      productId
      discountId
      product {
        cd
        id
        name
        tax
      }
      warehouse {
        id
        name
      }
    }
  }
`;
