import { gql } from '@apollo/client';
import { MCP_FRAGMENT } from './MCP_FRAGMENT';

export const CREATE_OR_UPDATE_MCP_MUTATION = gql`
  mutation CreateOrUpdateMcp($data: McpInput!) {
    mcp {
      createOrUpdateMcp(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...McpFragment
          mcpDistributors {
            id
            distributor {
              id
              name
            }
          }
        }
      }
    }
  }
  ${MCP_FRAGMENT}
`;
