import { gql } from '@apollo/client';
import { VISUAL_FRAGMENT } from './VISUAL_FRAGMENT';

export const CREATE_OR_UPDATE_VISUAL_MUTATION = gql`
  mutation CreateOrUpdateVisual($data: VisualInput!) {
    p {
      createOrUpdateVisual(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...VisualFragment
        }
      }
    }
  }
  ${VISUAL_FRAGMENT}
`;
