import { gql } from '@apollo/client';
import { KPI_FORMULA_FRAGMENT } from './KPI_FORMULA_FRAGMENT';

export const CREATE_OR_UPDATE_KPI_FORMULA_MUTATION = gql`
  mutation CreateOrUpdateKpiFormula($data: KpiFormulaInput!) {
    p {
      createOrUpdateKpiFormula(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...KpiFormulaFragment
        }
      }
    }
  }
  ${KPI_FORMULA_FRAGMENT}
`;
