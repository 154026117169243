import { ApolloError } from '@apollo/client';
import { CAlert } from '@coreui/react';
import React from 'react';
import { PayloadMessage } from '../queries';

export const DataMessage = ({
  error,
  message,
  dismissible,
}: {
  error?: ApolloError;
  message?: PayloadMessage | null;
  dismissible?: boolean;
}) => {
  return (
    <>
      {error && (
        <CAlert color="danger" dismissible={dismissible}>
          {error.message || 'Something went wrong, please try again later.'}
        </CAlert>
      )}
      {message && (
        <CAlert color="danger" dismissible={dismissible}>
          {message.message}
        </CAlert>
      )}
    </>
  );
};
