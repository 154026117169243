import { gql } from '@apollo/client';
import { CHECKOUT_REASON_FRAGMENT } from './CHECKOUT_REASON_FRAGMENT';

export const CHECKOUT_REASON_QUERY = gql`
  query CheckOutReason($id: Int!) {
    mcp {
      checkOutReason(id: $id) {
        ...CheckOutReasonFragment
      }
    }
  }
  ${CHECKOUT_REASON_FRAGMENT}
`;
