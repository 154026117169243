import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_MCP_LINE_MUTATION = gql`
  mutation CreateOrUpdateMcpLine($data: McpLineInput!) {
    mcp {
      createOrUpdateMcpLine(data: $data) {
        success
        message {
          code
          message
        }
        content {
          id
          weekdays
          weeksOfMonth
        }
      }
    }
  }
`;
