import { gql } from '@apollo/client';

export const COMMANDO_LINE_QUERY = gql`
  query CommandoLines($commandoId: Int!, $visitDate: Date, $page: Int!, $take: Int!) {
    mcp {
      commandoLines(commandoId: $commandoId, visitDate: $visitDate, page: $page, take: $take) {
        total
        data {
          id
          distributor {
            id
            cd
            name
          }
          visitDate
          startHour
          endHour
          targetAmount
          targetCustomer
          isActive
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
