import { gql } from '@apollo/client';
import { WORK_GROUP_FRAGMENT } from './WORK_GROUP_FRAGMENT';

export const WORK_GROUP_QUERY = gql`
query WorkGroup($id: Int!){
    md{
      workGroup(id: $id){
        ...WorkGroupFragment
      }
    }
  }
  ${WORK_GROUP_FRAGMENT}
`;
