import { gql } from '@apollo/client';
import { PRODUCT_GROUP_FRAGMENT } from './PRODUCT_GROUP_FRAGMENT';

export const PRODUCT_GROUPS_QUERY = gql`
  query ProductGroups($productGroupCd: String, $productGroupName: String, $isBundle: Boolean) {
    md {
      productGroups(
        productGroupCd: $productGroupCd
        productGroupName: $productGroupName
        isBundle: $isBundle
      ) {
        ...ProductGroupFragment
      }
    }
  }
  ${PRODUCT_GROUP_FRAGMENT}
`;
