import { gql } from '@apollo/client';

export const SURVEY_LIST_QUERY = gql`
  query SurveyList(
    $page: Int!
    $take: Int!
    $name: String
    $status: ProgramStatus
    $description: String
    $surveyCd: String
  ) {
    p {
      surveyList(
        page: $page
        take: $take
        name: $name
        status: $status
        description: $description
        surveyCd: $surveyCd
      ) {
        total
        data {
          id
          cd
          status
          title
          description
          startDate
          endDate
        }
      }
    }
  }
`;
