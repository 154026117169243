import { gql } from '@apollo/client';

export const WORK_GROUP_LIST_QUERY = gql`
query WorkGroupList($page: Int!, $take: Int!, $filter: String){
  md{
    workGroupList(page: $page, take: $take, filter: $filter){
      total
      data{
        id
        cd
        name
        workGId
        description
      }
    }
  }
}
`;
