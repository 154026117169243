export const logo = [
  '376 178',
  `
  <title>Apatek DMS logo</title>
  <g>
    <path class="prefix__cls-1"
        d="M128 56.124L89 33.608a12 12 0 00-12 0L38 56.124a12.034 12.034 0 00-6 10.393v45.033a12.034 12.034 0 006 10.392l39 22.517a12 12 0 0012 0l39-22.517a12.034 12.034 0 006-10.392V66.517a12.034 12.034 0 00-6-10.393zm-2 55.426a4 4 0 01-2 3.464l-39 22.517a4 4 0 01-4 0l-39-22.517a4 4 0 01-2-3.464V66.517a4 4 0 012-3.464l39-22.517a4 4 0 014 0l39 22.517a4 4 0 012 3.464z"
        fill="currentColor" />
    <path d="M72.396 65.011l14.94.61L99.82 78.008l3.48 17.06-11.462 16.451-19.647 2.64z" fill="none"
        stroke="currentColor" stroke-width="8.899" />

    <span style="line-height:1.25" x="140.729" y="105.006" fill="currentColor" font-weight="400" font-size="40"
        font-family="sans-serif">
        <tspan x="140.729" y="105.006" font-weight="700">APATEK</tspan>
    </span>

    <span style="line-height:1.25" x="302.027" y="72.169" fill="currentColor" font-weight="400" font-size="24"
        font-family="sans-serif">
        <tspan x="302.027" y="72.169" font-weight="700">DMS</tspan>
    </span></svg>
  </g>
`,
];
