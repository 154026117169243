import { gql } from '@apollo/client';

export const DELETE_VISUAL_MUTATION = gql`
  mutation DeleteVisual($id: Int!) {
    p {
      deleteVisual(id: $id) {
        success
        message {
          code
          message
        }
        content
      }
    }
  }
`;
