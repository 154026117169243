import gql from 'graphql-tag';

export const WORK_GROUP_FRAGMENT = gql`
  fragment WorkGroupFragment on WorkGroup {
    __typename
    id
    cd
    name
    description
    workGroups{
      id
      name
      workGId
      description
    }
    salesRegions {
      id
      name
      workGroupId
      description
    }
  }
`;
