import gql from 'graphql-tag';

export const DISCOUNT_FRAGMENT = gql`
  fragment DiscountFragment on Discount {
    __typename
    id
    cd
    description
    status
    startDate
    endDate
    isBundle
    breakBy
    multiple
    sortOrder
    highestLevel
    excluded
    allowChangeLevel
    isManual
    firstOrder
    internalRef
    externalRef
    sortOrder
    buyGive
    discountProducts {
      id
      productId
      rate
    }
    discountProductGroups {
      id
      productGroupId
    }
    discountLevels {
      id
      level
      description
      breakValue
      discountLevelRewards {
        id
        description
        isDefault
        isBundle
        amount
        percentage
        qty
        discountLevelRewardProducts {
          id
          productId
          rate
        }
      }
      discountLevelConditions {
        id
        productId
        minQty
      }
    }
    discountApplicableCustomers {
      id
      customer {
        id
        cd
        name
      }
    }
    discountApplicableCustomerAttrs {
      id
      attrId
      attrDetailId
    }
    discountApplicableSalesRegions {
      id
      salesRegion {
        id
        name
      }
    }
  }
`;
