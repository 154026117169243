import { gql } from '@apollo/client';

export const PRODUCT_GROUP_ITEMS_MUTATION = gql`
  mutation ProductGroupItems($id: Int!) {
    md {
      productGroupItems(id: $id) {
        id
        productId
        rate
      }
    }
  }
`;
