import { gql } from '@apollo/client';
import { KPI_CONFIG_FRAGMENT } from './KPI_CONFIG_FRAGMENT';

export const CREATE_OR_UPDATE_KPI_CONFIG_MUTATION = gql`
  mutation CreateOrUpdateKpiConfig($data: KpiConfigInput!) {
    p {
      createOrUpdateKpiConfig(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...KpiConfigFragment
        }
      }
    }
  }
  ${KPI_CONFIG_FRAGMENT}
`;
