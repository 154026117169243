import { gql } from '@apollo/client';
import { DISTRIBUTOR_FRAGMENT } from './DISTRIBUTOR_FRAGMENT';

export const CREATE_OR_UPDATE_DISTRIBUTOR_MUTATION = gql`
  mutation CreateOrUpdateDistributor($data: DistributorInput!) {
    md {
      createOrUpdateDistributor(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...DistributorFragment
        }
      }
    }
  }
  ${DISTRIBUTOR_FRAGMENT}
`;
