import gql from 'graphql-tag';

export const TRANSACTION_FRAGMENT = gql`
  fragment TransactionFragment on Transaction {
    __typename
    id
    source
    status
    description
    transactionDate
    salesOrderId
    purchaseReceiptId
    note
    reason {
      id
      name
      description
    }

    distributor {
      id
      name
    }

    creator {
      id
      name
    }

    fromWarehouse {
      id
      name
    }

    toWarehouse {
      id
      name
    }

    transactionItems {
      id
      type
      unitId
      unitPrice
      qty
      baseUnitId
      baseUnitPrice
      baseQty
      lotNbr
      startDate
      endDate
      exchangeLotNbr
      exchangeStartDate
      exchangeEndDate
      warehouse {
        id
        name
      }
      product {
        id
        name
      }
    }
  }
`;
