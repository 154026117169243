import { gql } from '@apollo/client';
import { SURVEY_FRAGMENT } from './SURVEY_FRAGMENT';

export const CREATE_OR_UPDATE_SURVEY_MUTATION = gql`
  mutation CreateOrUpdateSurvey($data: SurveyInput!) {
    p {
      createOrUpdateSurvey(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...SurveyFragment
        }
      }
    }
  }
  ${SURVEY_FRAGMENT}
`;
