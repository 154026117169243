import { gql } from '@apollo/client';

export const REPORT_VISITPLAN_SUMMARY_QUERY = gql`
  query ReportVisitPlanSummary(
    $startDate: Date!
    $endDate: Date!
    $salesmanId: Int
    $leaderId: Int
    $page: Int!
    $take: Int!
  ) {
    rp {
      reportVisitPlanSummary(
        startDate: $startDate
        endDate: $endDate
        salesmanId: $salesmanId
        leaderId: $leaderId
        page: $page
        take: $take
      ) {
        total
        data {
          mcpId
          mcp {
            mcpDistributors {
              distributor {
                cd
                name
              }
            }
            salesman {
              id
              cd
              name
              supervisor {
                cd
                name
              }
              workingSalesRegions {
                salesRegion {
                  cd
                  name
                }
              }
            }
          }
          date
          inRouteRequiredVisitCount
          inRouteVisitedCount
          outRouteVisitedCount
          orderCount
          orderTotal
          inRouteOrderCount
          inRouteOrderTotal
          outRouteOrderCount
          outRouteOrderTotal
          visitTimeMinutes
          movingTimeMinutes
          visitTimeAverageMinutes
        }
      }
    }
  }
`;
