import { gql } from '@apollo/client';

export const LOYALTY_LIST_QUERY = gql`
  query LoyaltyList(
    $startDate: Date
    $endDate: Date
    $tradeId: Int
    $salesRegionId: Int
    $status: ProgramStatus
    $target: TradeApplyTarget
    $filter: String
    $page: Int!
    $take: Int!
  ) {
    rp {
      loyaltyList(
        tradeId: $tradeId
        filter: $filter
        page: $page
        startDate: $startDate
        endDate: $endDate
        take: $take
        salesRegionId: $salesRegionId
        status: $status
        target: $target
      ) {
        total
        data {
          tradeId
          trade {
            cd
            description
            status
            breakBy
            tradeApplyTarget
            registerStartDate
            registerEndDate
            startDate
            endDate
            multiple
            autoLevel
            tradeProducts {
              productId
              rate
              product {
                name
              }
            }
            tradeApplicableCustomers {
              customer {
                cd
                name
              }
            }
            tradeApplicableCustomerAttrs {
              attrId
              attrDetailId
            }
            tradeApplicableSalesRegions {
              salesRegion {
                cd
                name
              }
            }
          }
          tradeLevel {
            level
            isBundle
            description
            breakValue
            amount
            percentage
            qty
          }
          tradeLevelProduct {
            productId
            rate
            product {
              name
            }
          }
        }
      }
    }
  }
`;
