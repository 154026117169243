import { gql } from '@apollo/client';

export const SHIPPER_QUERY = gql`
    query Shipper($id:Int!){
        md{
        shipper(id:$id){
            id
            cd
            name
            phone11
            email
            citizenIdentityCard
            photoId
            address1
            distributorId
            isAlive
            photo{
            id
            url
            }
        }
        }
    }
`;
