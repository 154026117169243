export const Constants = {
  defaultPage: 1,
  pageSizeDistributor: 100,
  pageSize: 20,
  pageSizes: [20, 30, 40],
  pageSizesConfig: [20, 30, 40, 100, 200],
  smallPageSize:20,
  smallPageSizes:[20, 30, 40],
  modalPageSize: 10,
  modalPageSizes: [10, 20, 30],
};
