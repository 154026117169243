import { gql } from '@apollo/client';
import { MCP_FRAGMENT } from './MCP_FRAGMENT';

export const MCP_QUERY = gql`
  query Mcp($id: Int!) {
    mcp {
      mcp(id: $id) {
        ...McpFragment
        mcpDistributors {
          id
          distributor {
            id
            cd
            name
          }
        }
      }
    }
  }
  ${MCP_FRAGMENT}
`;
