import { gql } from '@apollo/client';
import { SALES_ORDER_FRAGMENT } from './SALES_ORDER_FRAGMENT';

export const SALES_ORDER_QUERY = gql`
  query SalesOrder($id: Int!) {
    o {
      salesOrder(id: $id) {
        ...SalesOrderFragment
      }
    }
  }
  ${SALES_ORDER_FRAGMENT}
`;
